<template>
  <div class="about">
    <h2>Portal User Management</h2>
    <div class="mb-3 mt-3">
      <b-form inline @submit="onSubmit">
        <label for="inline-form-input-pws" class="mr-2">PWS ID</label>
        <b-form-input
          id="inline-form-input-pws"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="pws_id"
        ></b-form-input>

        <label for="inline-form-input-email" class="mr-2">Email</label>
        <b-form-input
          id="inline-form-input-email"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="email"
        ></b-form-input>

        <label for="inline-form-input-name" class="mr-2">Last Name</label>
        <b-form-input
          id="inline-form-input-name"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="last_name"
        ></b-form-input>
        <b-button variant="primary" @click="fetchUsers">Search</b-button>
      </b-form>
    </div>
    <b-table ref="user_table" striped bordered :fields="table_fields" :items="user_list">
      <template #cell(notes)="data">
        <b-button size="sm" @click="editNote(data.item)" variant="primary">Notes</b-button>
      </template>

      <template #cell(passcode)="data">
        <b-button size="sm" @click="fetchTOTP(data.item)" variant="outline-primary">{{ data.value }}</b-button>
      </template>
    </b-table>
    <b-modal id="modal-notes" size="lg" title="Notes" @ok="saveNotes">
      <b-form-textarea
        id="notes-textarea"
        v-model="notes"
        rows="10"
      ></b-form-textarea>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table_fields: [
        { key: "notes", label: "Notes" },
        { key: "active", label: "Active", sortable: true },
        { key: "email", label: "E-Mail", sortable: true },
        { key: "first_name", label: "First Name", sortable: true },
        { key: "last_name", label: "Last Name", sortable: true },
        { key: "passcode", label: "Passcode"},
        { key: "last_login_at", label: "Last Login TS", sortable: false },
      ],
      user_list: [],
      last_name: '',
      email: '',
      pws_id: '',
      user_id: null,
      notes: null,
      current_user_item: null,
      filter: null,
      filterOn: [],
      current_page: 1,
      per_page: 10
    }
  },
  mounted() {
    //pass
    //this.fetchUsers()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.fetchUsers()
    },
    editNote(item) {
      this.current_user_item = item
      this.user_id = item.id
      this.notes = item.notes
      this.$bvModal.show('modal-notes')
    },
    async saveNotes() {
      console.log(this.notes)
      console.log('saving notes for ID ' + this.user_id)
      if (await this.syncNotes()) {
        this.current_user_item.notes = this.notes
      }
    },
    async syncNotes() {
      const path = process.env.VUE_APP_API_URI + "/portal-user-notes"
      try {
        console.log("saving notes")
        await this.axios.post(path, { user_id: this.user_id, notes: this.notes })
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async fetchUsers() {
      const path = process.env.VUE_APP_API_URI + "/portal-users"
      try {
        console.log("fetching user list")
        const params = {
          last_name: this.last_name,
          email: this.email,
          pws_id: this.pws_id
        }
        let response = await this.axios.get(path, {params: params})
        if (response.data) {
          this.user_list = response.data
          this.user_list.forEach(element => { element.passcode = 'Generate' })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchTOTP(user) {
      const path = process.env.VUE_APP_API_URI + "/portal-user-totp"
      try {
        console.log("fetching user code")
        let response = await this.axios.get(path, {params: { user_id: user.id }})
        if (response.data) {
          if (response.data.msg) {
            console.log(response.data.msg)
            user.passcode = response.data.msg
            this.$refs.user_table.refresh()
          }
          else {
            const old_code = user.passcode
            user.passcode = response.data.code
            //user.first_name = 'Joe'
            // Force virtual column update
            console.log(response.data.code)
            if (user.passcode !== old_code) {
              console.log('forcing table refresh')
              this.$refs.user_table.refresh()
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

  }
}
</script>
